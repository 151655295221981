export default {
  /**
   * @name 申請成為頻道主/特約作者
   */
  baseUrl: '/applicationForm',
  index: {
    url: '',
    method: 'file',
    data: {
      type: 1, // 1:creator 我的頻道, 2:author 我的創作
      name: '',
      cate_id: 0,
      description: '',
      about_me: '',
      taget: '',
      apply_publish: 0,
      file: '',
      url: '',
    },
  },
};

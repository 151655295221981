export default {
  /**
   * @name 登入
   */
  baseUrl: '/login',
  index: {
    url: '',
    method: 'post',
    data: {
      username: true,
      password: true,
    },
    token: false,
  },
  forget: {
    url: '/forget',
    token: false,
    params: {
      // email: '',
      // phone: '',
    },
  },
  phoneVerify: {
    url: '/phoneVerify',
    token: false,
    params: {
      phone: '',
      code: '',
    },
  },
};

// 頻道類別
export const setChannelCategory = (state, list) => {
  state.channelCategory = list;
};

// 閱讀時段
export const setReadingPeriod = (state, list) => {
  state.readingPeriod = list;
};

// 興趣標籤
export const setInterestTags = (state, list) => {
  state.interestTags = list;
};

// 能力標籤
export const setAbilityTags = (state, list) => {
  state.abilityTags = list;
};

// 文章標籤
export const setArticleTags = (state, list) => {
  state.articleTags = list;
};

// 搜尋標籤
export const setSearchTags = (state, list) => {
  state.searchTags = list;
};

// 我的頻道 播放清單 下拉
export const setCreatorPlaylists = (state, list) => {
  state.creatorPlaylists = list;
};

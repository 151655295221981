<template>
  <div id="app">
    <router-view/>
    <SvgGradient />
    <Notify />
    <Modal />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import SvgGradient from '@/components/base/SvgGradient.vue';
import Notify from '@/components/Notify.vue';
import Modal from '@/components/Modal.vue';
import screenMixin from '@/mixins/screenMixin';

export default {
  name: 'App',
  mixins: [screenMixin],
  components: {
    SvgGradient,
    Notify,
    Modal,
  },
  created() {
    console.info(
      '%c build ',
      'background-color:#E94057;color:#fff',
      process.env.GIT_HASH,
      process.env.PUBLISH_TIME,
    );
  },
  mounted() {
    this.methodInit();
  },
  methods: {
    ...mapActions({
      methodInit: 'init',
    }),
  },
};
</script>

import { getStorage } from '../utils/timerStorage';

export default function () {
  return {
    init: false,
    isLogin: false,
    user: (getStorage('isLogin') && getStorage('user')) || {},

    isLoading: false,

    // notify ['success', 'error', 'warning', 'info']
    notify: {
      show: false,
      type: 'success',
      title: '',
      message: '',
      timeout: 3000,
    },

    modal: {
      show: false,
      width: 800,
      closeButton: true,
      persistent: false,
      propsData: {},
      contentComponent: null,
      confirmCallback: null,
      closeCallback: null,
      confirmFuncName: null,
      concelCallback: null,
    },
  };
}

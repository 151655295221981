export default {
  /**
   * @name 文章
   */
  baseUrl: '/download',
  index: {
    url: '',
    token: true,
    // download: true,
    params: {
      uuid: '',
    },
  },
  notify: {
    url: '/notify',
    // token: true,
    // download: true,
    params: {
      uuid: '',
    },
  },
  file: {
    url: '/file',
    // token: true,
    download: true,
    params: {
      uuid: '',
    },
  },
};

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/Index.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/home/Index.vue'),
        meta: {
          isPublic: true,
        },
      },
      {
        path: 'download',
        name: 'Download',
        component: () => import('@/views/downloads/Index.vue'),
        meta: {
          isPublic: true,
        },
      },
      {
        path: 'success',
        name: 'Success',
        component: () => import('@/views/success/Index.vue'),
        meta: {
          isPublic: true,
        },
      },
      {
        path: 'error',
        name: 'Error',
        component: () => import('@/views/error/Index.vue'),
        meta: {
          isPublic: true,
        },
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/layouts/Footer.vue'),
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/login/Index.vue'),
        meta: {
          isPublic: true,
        },
      },
      {
        path: 'signup',
        name: 'Signup',
        props: true,
        component: () => import('@/views/signup/Index.vue'),
        meta: {
          isPublic: true,
        },
      },
      {
        path: 'forgot',
        name: 'Forgot',
        props: true,
        component: () => import('@/views/forgot/Index.vue'),
        meta: {
          isPublic: true,
        },
      },
      {
        path: 'resetpassword',
        name: 'ResetPassword',
        component: () => import('@/views/resetPassword/Index.vue'),
        meta: {
          isPublic: true,
        },
      },
    ],
  },
  {
    path: '*',
    component: () => import('@/views/Error.vue'),
  },
];

export default routes;

import requestApi from '../../lib/http';
import {
  formatCategory,
} from '../../utils/formatResponse';

// 取得系統參數
export const getSysVars = async ({ commit }) => {
  const { status, message, result } = await requestApi('sysVars');
  if (status) {
    const categories = result?.categories ?? [];

    // 1 頻道主分類 熱門頻道上面的tag
    // 2 閱讀場景
    // 3 會員有興趣的
    // 4 知識
    // 5 能力
    // 我的專屬推薦 喜愛標籤 4 + 5 顯示user有選起來的標籤
    // 6 精選專題 首頁的精選專題tag
    commit('setChannelCategory', categories.filter(({ type } = {}) => type === 1).map(formatCategory));
    commit('setInterestTags', categories.filter(({ type } = {}) => type === 4).map(formatCategory));
    commit('setAbilityTags', categories.filter(({ type } = {}) => type === 5).map(formatCategory));

    const sysVars = result?.sysVars ?? [];

    // 閱讀時段
    const readingPeriod = sysVars.filter(({ keyword } = {}) => keyword === 'readingPeriod');
    commit('setReadingPeriod', readingPeriod[0].children.map(formatCategory));
    return false;
  }
  return message;
};

// 取得標籤
export const getTags = async ({ commit }) => {
  const { status, message, result } = await requestApi('tags');
  if (status) {
    let {
      article: articleTags = [],
      search: searchTags = [],
    } = result?.data ?? {};

    articleTags = articleTags.map(({ id: value, name: label }) => ({
      label,
      value,
    }));
    searchTags = searchTags.map(({ id: value, name: label }) => ({
      label,
      value,
    }));

    commit('setArticleTags', articleTags);
    commit('setSearchTags', searchTags);
    return false;
  }
  return message;
};

// 取得 我的頻道 播放清單下拉
export const getCreatorPlaylists = async ({ commit }) => {
  const { status, message, result } = await requestApi('creatorArticles.playlists');
  if (status) {
    const data = result?.data ?? [];

    const list = data.map(formatCategory);
    commit('setCreatorPlaylists', list);
    return false;
  }
  return message;
};

export default {
  /**
   * @name 標籤
   */
  baseUrl: '/tags',
  index: {
    url: '',
    token: false,
  },
};

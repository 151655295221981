export default {
  /**
   * @name 播放清單內頁
   */
  baseUrl: '/playlist-view',
  index: {
    url: '',
    params: {
      uuid: '',
    },
  },
};

<template>
  <div
    class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-40"
  >
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-class="translate-x-2 opacity-0"
        enter-to-class="translate-x-0 opacity-100"
        leave-active-class="transform ease-in duration-100 transition"
        leave-class="translate-x-0 opacity-100"
        leave-to-class="translate-x-2 opacity-0"
      >
      <div
        v-show="show"
        class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1
        ring-black ring-opacity-5 overflow-hidden"
        @mouseover="retainShow = true"
        @mouseleave="retainShow = false"
      >
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <DgIcon
                size="22"
                :name="icon"
                :color="iconColor"
              />
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p
                v-show="title"
                class="text-sm font-medium text-ash-800 mb-1"
              >
                {{title}}
              </p>
              <p
                v-show="message"
                class="text-sm text-ash-600"
              >
                {{message}}
              </p>
            </div>
            <div class="ml-4 flex-shrink-0 flex">
              <button
                @click="methodCloseNotify"
              >
                <DgIcon
                  size="14"
                  name="times"
                  color="ash-600"
                />
              </button>
            </div>
          </div>
        </div>
        </div>
      </transition>
    </div>
  </div>

</template>

<script>
import { mapState, mapMutations } from 'vuex';
import DgIcon from '@/components/base/DgIcon.vue';
import { iconList } from '@/utils/const';

export default {
  name: 'Notify',
  components: {
    DgIcon,
  },
  data() {
    return {
      retainShow: false,
      timer: null,
      countdown: 0,
      iconList,
      iconColorList: {
        success: 'green-500',
        error: 'red-500',
        warning: 'yellow-500',
        info: 'blue-500',
      },
    };
  },
  computed: {
    ...mapState({
      show: (state) => state.notify.show,
      type: (state) => state.notify.type,
      title: (state) => state.notify.title,
      message: (state) => state.notify.message,
      timeout: (state) => state.notify.timeout,
    }),
    icon() {
      return this.iconList[this.type] ?? this.iconList.success;
    },
    iconColor() {
      return this.iconColorList[this.type] ?? this.iconColorList.success;
    },
  },
  watch: {
    show(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        if (this.timeout > 0) {
          this.countdown = this.timeout;
          this.methodTimeToClose();
        }
      }
    },
    retainShow(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal) {
          clearInterval(this.timer);
        } else {
          this.methodTimeToClose();
        }
      }
    },
  },
  methods: {
    ...mapMutations({
      mutationSetNotify: 'setNotify',
    }),
    methodTimeToClose() {
      this.timer = setInterval(() => {
        this.countdown -= 1000;
        if (this.countdown <= 0) {
          this.methodCloseNotify();
          clearInterval(this.timer);
        }
      }, 1000);
    },
    methodCloseNotify() {
      this.mutationSetNotify({
        show: false,
      });
    },
  },
};
</script>

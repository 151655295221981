export default {
  /**
   * @name 文章
   */
  baseUrl: '/articles',
  index: {
    url: '',
    token: false,
    params: {
      limit: '',
      tag_id: [],
    },
  },
  view: {
    url: '/view',
    token: false,
    params: {
      uuid: '',
    },
  },
  send: {
    url: '/send',
    token: true,
    params: {
      uuid: '',
    },
  },
  // comments: {
  //   url: '/comments',
  //   params: {
  //     uuid: '',
  //     sort: '', // new, hot
  //   },
  // },
  // more: {
  //   url: '/more',
  //   params: {
  //     uuid: '',
  //   },
  // },
};

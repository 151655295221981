import { setStorage } from '../utils/timerStorage';

export const setInit = (state) => {
  state.init = true;
};
// 登入, 登出
export const setIsLogin = (state, status) => {
  setStorage('isLogin', status);
  state.isLogin = status;
};

// 設置會員資料
export const setUserInfo = (state, info) => {
  setStorage('user', info);
  state.user = info;
};

// loading
export const setIsLoading = (state, status) => {
  state.isLoading = status;
};

// nofity
export const setNotify = (state, info) => {
  state.notify = {
    ...state.notify,
    ...info,
  };
};
export const setNotifySuccess = (state, message) => {
  state.notify = {
    ...state.notify,
    show: true,
    type: 'success',
    message,
  };
};
export const setNotifyError = (state, message) => {
  state.notify = {
    ...state.notify,
    show: true,
    type: 'error',
    message,
  };
};

// modal
export const setModal = (state, info) => {
  state.modal = {
    ...state.modal,
    ...info,
  };
};

<template>
  <svg class="fixed">
    <linearGradient
      v-for="(direction, idx) in list"
      :key="`svg-gradient-item-${direction}-${idx}`"
      :id="`primary-gradient-to-${direction}`"
      :x1="directions[direction].x1"
      :y1="directions[direction].y1"
      :x2="directions[direction].x2"
      :y2="directions[direction].y2"
    >
      <stop offset="0%" :stop-color="gradientColor['gradient-dark-blue']" />
      <stop offset="100%" :stop-color="gradientColor['gradient-light-blue']" />
    </linearGradient>
  </svg>
</template>

<script>
import { gradientColor } from '@/lib/settings';

export default {
  name: 'SvgGradient',
  data() {
    return {
      gradientColor,
      directions: {
        t: {
          x1: '0%',
          y1: '100%',
          x2: '0%',
          y2: '0%',
        },
        tr: {
          x1: '0%',
          y1: '100%',
          x2: '100%',
          y2: '0%',
        },
        r: {
          x1: '0%',
          y1: '0%',
          x2: '100%',
          y2: '0%',
        },
        br: {
          x1: '0%',
          y1: '0%',
          x2: '100%',
          y2: '100%',
        },
        b: {
          x1: '0%',
          y1: '0%',
          x2: '0%',
          y2: '100%',
        },
        bl: {
          x1: '100%',
          y1: '0%',
          x2: '0%',
          y2: '100%',
        },
        l: {
          x1: '100%',
          y1: '0%',
          x2: '0%',
          y2: '0%',
        },
        tl: {
          x1: '100%',
          y1: '100%',
          x2: '0%',
          y2: '0%',
        },
      },
    };
  },
  computed: {
    list() {
      return Object.keys(this.directions);
    },
  },
  methods: {

  },
};
</script>

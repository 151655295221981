export default {
  /**
   * @name 會員
   */
  baseUrl: '/users',
  index: {
    url: '',
  },
  // profile: {
  //   url: '/profile',
  // },
  signUp: {
    url: '/sign-up',
    method: 'post',
    token: false,
    data: {
      realname: '',
      company_name: '',
      email: '',
      phone: '',
      password: '',
      // inv_code: '',
    },
  },
  // 重置密碼
  resetPassword: {
    url: '/resetPassword',
    token: false,
    method: 'post',
    params: {
      password: '',
    },
    errorMsgTrans: {
      password: '密碼',
    },
  },
};

export default {
  /**
   * @name 影片轉檔相關
   */
  baseUrl: '/straasFiles',
  // 取得上傳網址
  uploadUrl: {
    url: '/uploadUrl',
    method: 'post',
    data: {
      title: '',
      type: '',
      // TODO: rm
      user_uuid: '',
    },
  },
};

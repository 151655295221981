export default {
  /**
   * @name 留言
   */
  baseUrl: '/comments',
  add: {
    url: '',
    method: 'post',
    params: {
      article_uuid: true,
      parent_id: '',
      content: true,
    },
  },
};

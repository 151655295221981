import Vue from 'vue';
import VueRouter from 'vue-router';
import { getStorage } from '../utils/timerStorage';
// import Cookies from 'js-cookie';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    const scrollContent = document.querySelector('.hash-content') || document.querySelector('#app');
    if (
      to?.name === from?.name
      || to.meta.originPosition) {
      return false;
    } if (savedPosition) {
      return scrollContent.scrollIntoView({
        ...savedPosition,
        behavior: 'smooth',
      });
    }
    return scrollContent.scrollIntoView({ behavior: 'smooth' });
  },
});

router.beforeEach((to, from, next) => {
  // TODO: test
  // if (to.name === 'MyCreation') return false;
  if (to?.name === 'ChannelResult' && !to?.params?.type) {
    return next({
      name: 'Home',
    });
  }
  if (!to?.meta?.isPublic && !getStorage('isLogin')) {
    return next({
      name: 'Home',
    });
  }
  return next();
});

export default router;

export default {
  /**
   * @name 頻道設定
   */
  baseUrl: '/creators',
  index: {
    url: '',
    data: {
      uuid: '',
    },
  },
  update: {
    url: '/update',
    method: 'file',
    data: {
      name: '', // 頻道名稱
      cate_id: '', // 頻道分類
      description: '', // 頻道說明
      about_me: '', // 頻道主介紹
      email: '', // email
      phone: '', // phone
      fb_url: '', // fb url
      ig_url: '', // ig url
      line_url: '', // line url
      banner_file: '', // 頻道封面
      article_top_id: '', // 主打知識
      channel_menus: [], // 頻道選單
      channel_layout: [], // 頻道版面
    },
  },
  info: {
    url: '/info',
  },
};

export const iconList = {
  success: 'check-circle',
  error: 'times-circle',
  warning: 'exclamation-circle',
  info: 'info-circle',
};

export const tagList = [
  {
    label: '標籤',
    uniKey: 'tag',
    selectable: true,
    multi: true,
    all: true,
    list: [
      {
        label: '全部標籤',
        value: 'all',
      },
      {
        label: '企業通雜誌',
        value: '1',
      },
      {
        label: '方案介紹',
        value: '2',
      },
      {
        label: '研討會講義',
        value: '3',
      },
      {
        label: '線上研討會回看',
        value: '4',
      },
      {
        label: '電子報/專欄訂閱',
        value: '5',
      },
    ],
  },
];

const loremText = (n, min, type) => {
  const num = '0123456789';
  const en = 'abcdefghijklmnopqrstuvwxyz ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const zh = '到底什麼是數據賦能！埃森哲告訴你數位轉型最熱的名詞最新熱門喜歡留言會員中美貿易生產供應PM難不難溝通不容易職場EQ';
  let text = zh;
  if (type === 'en') text = en;
  if (type === 'num') text = num;
  const loremN = Math.round(n * Math.random()) + min;
  return Array.from(Array(loremN)).map(() => text[Math.round(text.length * Math.random())]).join('');
};
const loremBoolean = () => !!Math.round(1 * Math.random());
const loremArrayText = (n) => {
  const loremN = Math.round(n * Math.random());
  return Array.from(Array(loremN)).map(() => loremText(6, 2));
};
const loremAvatar = () => {
  const count = Math.round(99 * Math.random()) + 1;
  const gender = loremBoolean() ? 'women' : 'men';
  return `https://randomuser.me/api/portraits/${gender}/${count}.jpg`;
};
const loremDate = () => new Date(`2021/${Math.round(4 * Math.random()) + 1}/${Math.round(25
  * Math.random()) + 1} ${Math.round(11 * Math.random()) + 1}:${Math.round(59
    * Math.random())}:${Math.round(59 * Math.random()) + 0}`);

export const tmpList = (() => Array.from(Array(20)).map(() => ({
  image: 'https://cnet2.cbsistatic.com/img/9tf6BfVtKOpwjMwKYAc2usl0WRg=/105x158:744x517/1200x675/2020/01/20/ada6b2c1-465f-409e-8956-df61acd3a067/bouqs-valentines-day-flowers.jpg',
  bookmark: loremBoolean(),
  length: Math.round(59 * Math.random()) * 60 + Math.round(59 * Math.random()),
  tag: loremArrayText(6),
  title: loremText(34, 5),
  avatar: loremAvatar(),
  name: loremText(16, 8, 'en'),
  created: loremDate(),
  likeCount: Math.round(1000 * Math.random()),
  watchedCount: Math.round(9999 * Math.random()),
})))();

<template>
  <component
    :is="icon"
    role="img"
    :class="[
      'dg-icon  fill-current',
      `size-${size}`,
      colorClass,
      hoverColorClass
    ]"
    :style="{
      width: `${size}px`,
      ...gradientFill,
    }"
  />
</template>

<script>
const requireComponents = require.context('../../assets/icons', true, /.svg$/);
const iconList = requireComponents.keys().map((path) => (path.replace(/^\.\/(.+)\.svg$/, '$1')));

export default {
  name: '',
  props: {
    name: {
      type: String,
      default: 'times',
      required: true,
    },
    size: {
      type: [String, Number],
      default: '36px',
    },
    color: {
      type: String,
      default: 'primary',
    },
    hoverColor: {
      type: String,
    },
  },
  data() {
    return {
      iconList,
    };
  },
  computed: {
    isCustomIcon() {
      return this.iconList.includes(this.name);
    },
    icon() {
      // eslint-disable-next-line global-require, import/no-dynamic-require
      return require(`@/assets/icons/${this.name}.svg`);
    },
    isGradient() {
      return /^primary-gradient-to/.test(this.color);
    },
    colorClass() {
      return this.isGradient ? '' : `text-${this.color}`;
    },
    hoverColorClass() {
      return this.hoverColor
        ? `group-hover:text-${this.hoverColor} hover:text-${this.hoverColor}`
        : '';
    },
    gradientFill() {
      return this.isGradient ? {
        fill: `url(#${this.color})`,
      } : null;
    },
  },
  methods: {

  },
};
</script>
<style lang="scss" scoped>
.vs-icon {
  svg {
    width: 100%;
    height: 100%;
  }
}
</style>

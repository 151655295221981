import requestApi, { setToken } from '../lib/http';
import { getStorage, setStorage, clearStorage } from '../utils/timerStorage';
import { isEmpty } from '../lib/lodash';

// 初始化
export const init = async ({ dispatch, commit }) => {
  const token = getStorage('token');
  if (token) {
    await dispatch('handleLoginInfo', { token });
  }
  commit('setInit');
};

// 註冊後 純粹為了註冊系列彈窗 設置 token
// eslint-disable-next-line no-unused-vars
export const setRegisterToken = async ({ dispatch }, token) => {
  // console.log('setRegisterToken', token, dispatch);
  if (token) {
    // console.log('setRegisterToken token', token);
    const expiredRange = 365 * 24 * 60 * 60 * 1000;
    const expiredIn = Date.now() + expiredRange;
    setStorage('token', token, expiredIn);
    setToken(token);
  }
};

export const handleLoginInfo = async ({ commit, dispatch }, { token }) => {
  if (token) {
    const expiredRange = 365 * 24 * 60 * 60 * 1000;
    const expiredIn = Date.now() + expiredRange;
    setStorage('token', token, expiredIn);
    setToken(token);
    const userInfoStatus = await dispatch('updateUserProfile');
    commit('setIsLogin', userInfoStatus);
    return true;
  }
  await dispatch('logout');
  return false;
};

export const logout = ({ commit }) => {
  commit('setIsLogin', false);
  clearStorage();
};

export const updateUserProfile = async ({ commit, dispatch }) => {
  const { status, result } = await requestApi('users');
  if (status) {
    const {
      picture: avatar = '',
      id = '',
      uuid = '',
      realname = '',
      username = '',
      email = '',
      invitation: invitationCode = '',
      hp_count: score = 0,
      creator = {},
      author = {},
    } = result?.data ?? {};
    const userInfo = {
      avatar,
      id,
      uuid,
      realname,
      username,
      email,
      invitationCode,
      score,
      havePublishAuthority: !!(creator?.publish ?? false),
      isCreator: !isEmpty(creator),
      creatorUuid: !isEmpty(creator) ? creator?.uuid ?? '' : null,
      isAuthor: !isEmpty(author),
    };
    commit('setUserInfo', userInfo);
  } else {
    await dispatch('logout');
  }
  return status;
};

export default {
  /**
   * @name 頻道播放清單
   */
  baseUrl: '/creator-playlists',
  index: {
    url: '',
    params: {
      uuid: '',
    },
  },
  add: {
    url: '',
    method: 'post',
    data: {
      name: '', // 名稱
      rank: 0, // -1:自己, 0:不限, 1:會員, 2:vip
    },
  },
  delete: {
    url: '/delete',
    method: 'post',
    data: {
      uuids: true, // array
    },
  },
  addArticles: {
    url: '/add-articles',
    method: 'post',
    data: {
      uuid: true, // playlists_uuid
      article_uuid: true, //  article_uuid
    },
  },
  deleteArticles: {
    url: '/delete-articles',
    method: 'post',
    data: {
      uuid: true, // playlists_uuid
      article_uuid: true, //  article_uuid
    },
  },
  weightArticles: {
    url: '/weight-articles',
    method: 'post',
    data: {
      uuid: true,
      article_uuid: true,
    },
  },
};

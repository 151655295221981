<template>
  <div
    class="fixed inset-0 z-30"
    v-show="open"
  >
    <div
      class="flex items-center justify-center h-screen"
    >
      <transition
        enter-active-class="ease-out duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="ease-in duration-200"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-show="show"
          @click="!persistent && methodCloseModal()"
          class="fixed inset-0 bg-black bg-opacity-70 transition-opacity"
        ></div>
      </transition>

      <transition
        enter-active-class="ease-out duration-300"
        enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enter-to-class="opacity-100 translate-y-0 sm:scale-100"
        leave-active-class="ease-in duration-200"
        leave-class="opacity-100 translate-y-0 sm:scale-100"
        leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div
          v-show="show"
          class="bg-white rounded-lg shadow-xl overflow-hidden relative mx-5"
          :class="width ? 'w-full': ''"
          :style="modalMaxWidth"
        >
          <!-- content -->
          <div
            class="overflow-y-auto"
            :style="{'max-height': 'calc(100vh - 64px)'}"
          >
            <!-- close button -->
            <div class="absolute top-6 right-6 z-10">
              <button
                v-if="closeButton"
                @click="methodCloseModal"
              >
                <DgIcon
                  size="30"
                  name="times-circle"
                  color="ash-200"
                />
              </button>
            </div>
            <components
              v-show="contentComponent"
              :is="contentComponent"
              v-bind="propsData"
              @confirm="methodConfirm"
              @cancel="methodCancel"
              @close="methodCloseModal"
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import DgIcon from '@/components/base/DgIcon.vue';

export default {
  name: 'Modal',
  components: {
    DgIcon,
  },
  data() {
    return {
      open: false,
      countdown: 0,
    };
  },
  computed: {
    ...mapState({
      show: (state) => state.modal.show,
      width: (state) => state?.modal?.width ?? 800,
      closeButton: (state) => state.modal.closeButton,
      persistent: (state) => state.modal.persistent,
      propsData: (state) => state.modal?.propsData ?? {},
      confirmCallback: (state) => state.modal.confirmCallback,
      confirmFuncName: (state) => state.modal.confirmFuncName,
      concelCallback: (state) => state.modal.concelCallback,
      closeCallback: (state) => state.modal.closeCallback,
      contentComponent: (state) => state?.modal?.contentComponent,
    }),
    modalMaxWidth() {
      return this.width ? {
        'max-width': `${this.width}px`,
      } : null;
    },
    funcNames() {
      return {
        login: this.methodGoLogin,
      };
    },
  },
  watch: {
    show(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal) {
          this.open = true;
        } else {
          setTimeout(() => {
            this.open = false;
            this.methodClearModalInfo();
          }, 200);
        }
      }
    },
  },
  methods: {
    ...mapMutations({
      mutationSetModal: 'setModal',
    }),
    methodCloseModal() {
      this.mutationSetModal({
        show: false,
      });
      if (this.closeCallback) this.closeCallback();
    },
    methodClearModalInfo() {
      this.mutationSetModal({
        width: 800,
        closeButton: true,
        persistent: false,
        propsData: {},
        contentComponent: null,
        confirmCallback: null,
        confirmFuncName: null,
        concelCallback: null,
      });
    },
    methodConfirm(v) {
      if (this.confirmCallback) this.confirmCallback(v);
      if (this.confirmFuncName) this.funcNames[this.confirmFuncName](v);
      this.methodCloseModal();
    },
    methodCancel(v) {
      if (this.concelCallback) this.concelCallback(v);
      this.methodCloseModal();
    },
    methodGoLogin() {
      this.$router.push({
        name: 'Login',
      });
    },
  },
};
</script>

export default {
  /**
   * @name 我的頻道>留言管理
   */
  baseUrl: '/creator-comments',
  index: {
    url: '',
    params: {
      comments: null, // 0:沒有回覆 1:有回覆留言
      sort: 'id', // id(預設) / likes
    },
  },
  toggle: {
    url: '/toggle',
    method: 'post',
    params: {
      uuid: '', // comment_uuid
    },
  },
};

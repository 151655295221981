import moment from 'moment';
import numeral from 'numeral';

// 數字
export const formatThousandthComma = (n) => numeral(n).format('0,0');

export const formatAbbreviate = (n) => numeral(n).format('0[.]0a');

export const formatBytes = (n) => numeral(n).format('0b');

export const videoLength = (length) => {
  if (length) {
    let str = '';
    const h = String(Math.floor(length / 3600));
    if (+h) {
      str += `${h}:`;
    }
    const m = String(Math.floor((length % 3600) / 60));
    const s = String(Math.floor((length % 3600) % 60));
    str += `${m}:${s}`;
    return str;
  } return '';
};

// 文字
export const capitalize = (v) => {
  if (!v) return '';
  const res = v.toString();
  return res.charAt(0).toUpperCase() + res.slice(1);
};

// 時間
export const formatDateTime = (date, format = 'YYYY/MM/DD HH:mm:ss') => moment(date).format(format);

export const formatFromNow = (date) => moment(date).fromNow();

export const durationCH = (date) => {
  moment.updateLocale('zh-tw', {
    relativeTime: {
      future: '%s内',
      past: '%s前',
      s: '幾秒',
      m: '1 分鐘',
      mm: '%d 分鐘',
      h: '1 小時',
      hh: '%d 小時',
      d: '1 天',
      dd: '%d 天',
      M: '1 個月',
      MM: '%d 個月',
      y: '1 年',
      yy: '%d 年',
    },
  });
  return moment(date).locale('zh-tw').from();
};

export const dateFormat = (date) => {
  const dateArr = date.split('-');
  return `${dateArr[0]}年${dateArr[1]}月${dateArr[2]}日`;
};

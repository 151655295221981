import sysVars from './sysVars';
import login from './login';
import users from './users';
import home from './home';
import applicationForm from './applicationForm';
import creatorArticles from './creatorArticles';
import creatorPlaylists from './creatorPlaylists';
import authorArticles from './authorArticles';
import tags from './tags';
import articles from './articles';
import downloads from './downloads';
import straasFiles from './straasFiles';
import creators from './creators';
import creatorComments from './creatorComments';
import comments from './comments';
import playlistView from './playlistView';
import authors from './authors';

export default {
  sysVars,
  login,
  users,
  home,
  applicationForm,
  creatorArticles,
  creatorPlaylists,
  authorArticles,
  tags,
  articles,
  downloads,
  straasFiles,
  creators,
  creatorComments,
  comments,
  playlistView,
  authors,
};

export default {
  provide() {
    return {
      device: this.device,
    };
  },
  data() {
    return {
      device: {
        screenWidth: 0,
        name: '',
        isMobile: false,
        isPc: false,
      },
      resizeTimeout: null,
    };
  },
  mounted() {
    if (this.$root === this.$parent) {
      this.setDevideData(this.getClientWidth());
      window.addEventListener('resize', () => {
        if (this.resizeTimeout) return;
        this.resizeTimeout = setTimeout(() => {
          this.setDevideData(this.getClientWidth());
          this.resizeTimeout = null;
        }, 100);
      }, { passive: true });
    }
  },
  methods: {
    getClientWidth() {
      return Math.max(
        window.document.documentElement.clientWidth,
        window.innerWidth || 0,
      );
    },
    setDevideData(width) {
      this.device.screenWidth = width;
      if (width < 640) this.device.name = 'sm';
      if (width < 768) this.device.name = 'md';
      if (width < 1024) this.device.name = 'lg';
      if (width < 1280) this.device.name = 'xl';
      if (width > 1536) this.device.name = '2xl';
      this.device.isMobile = width < 1024;
      this.device.isPc = width >= 1024;
    },
  },
};

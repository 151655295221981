export default {
  /**
   * @name 登入
   */
  baseUrl: '/home',
  // index: {
  //   url: '',
  //   method: 'post',
  //   data: {
  //     username: true,
  //     password: true,
  //   },
  //   token: false,
  // },
  carousel: {
    url: '/carousel',
    token: false,
  },
  everybody: {
    url: '/everybody',
    token: false,
  },
  promoCreators: {
    url: '/promoCreators',
    token: false,
  },
  readingCates: {
    url: '/readingCates',
    token: false,
  },
  readingArticles: {
    url: '/readingArticles',
    token: false,
    params: {
      cate_id: '',
    },
  },
};

export default function () {
  return {
    // 頻道類別
    channelCategory: [],
    // 閱讀時段
    readingPeriod: [],
    // 興趣標籤
    interestTags: [],
    // 能力標籤
    abilityTags: [],

    // 內容標籤
    articleTags: [],
    searchTags: [],

    // 我的頻道 播放清單 下拉
    creatorPlaylists: [],
  };
}

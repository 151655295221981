export default {
  /**
   * @name 我的創作
   */
  baseUrl: '/authorArticles',
  index: {
    url: '',
    params: {
      type: '', // 0:文章, 1:影片, 2:音頻, 3:文庫
      flag: '', // 0:待審核, 1:通過, 2:末通過
      keyword: '',
    },
  },
  // 預覽內容
  view: {
    url: '/view',
    params: {
      uuid: '',
    },
  },
  // 新增
  add: {
    url: '',
    method: 'file',
    data: {
      type: 0, // 0:文章, 1:影片, 2:音頻, 3:文庫
      creator_uuid: '', // 頻道uuid
      title: '', // 標題
      description: '', // 說明
      file: null, // 檔案
      type_file: null, // 檔案
      cover_file: '', // 封面
      tag_lists: [], // 標籤
      rank: 0, // -1:自己, 0:不限, 1:會員, 2:vip
      content: '', // 內容
      playlist_id: 0, // 清單
    },
  },
  // 編輯
  update: {
    url: '/update',
    method: 'file',
    data: {
      uuid: '',
      type: 0, // 0:文章, 1:影片, 2:音頻, 3:文庫, 4:貼文
      creator_uuid: '', // 頻道uuid
      title: '', // 標題
      description: '', // 說明
      file: null, // 檔案
      type_file: null, // 檔案
      cover_file: '', // 封面
      tag_lists: [], // 標籤
      rank: 0, // -1:自己, 0:不限, 1:會員, 2:vip
      content: '', // 內容
      playlist_id: 0, // 清單
    },
  },
  files: {
    url: '/files',
    method: 'file',
    data: {
      uuid: '',
      files: {}, // 檔案
    },
  },
  // 發佈頻道列表
  creatorLists: {
    url: '/creatorLists',
  },
  // 播放清單
  playlists: {
    url: '/playlists',
    params: {
      creator_uuid: '',
    },
  },
  // 審核紀錄
  review: {
    url: '/review',
    params: {
      uuid: '',
    },
  },
};

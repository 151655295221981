import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import './plugins/filters';
import './plugins/swiper';
import './plugins/copy';
import './plugins/hammer';
import './plugins/vueMeta';

import '@/assets/scss/main.scss';

// import '@/assets/scss/public.scss';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

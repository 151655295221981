export default {
  /**
   * @name 我的頻道
   */
  baseUrl: '/creatorArticles',
  // 內容管理 列表
  index: {
    url: '',
    params: {
      // -1 => '轉檔中',
      // 0 => '待審核',
      // 1 => '初審通過',
      // -2 => '初審不通過',
      // 2 => '頻道主通過',
      // -3 => '頻道主不通過',
      // 9 => '非法操作',
      // -9 => '永久刪除',
      flag: '',
      type: '', // 0:文章 1:影音 2:音頻 3:文庫 4:貼文
      status: '', // 0:下架 1:上架
      keyword: '', // title
    },
  },
  // 特約作者 列表
  authors: {
    url: '/authors',
    params: {
      flag: '',
      type: '', // 0:文章 1:影音 2:音頻 3:文庫 4:貼文
      status: '', // 0:下架 1:上架
      keyword: '', // title
    },
  },
  // 預覽內容
  view: {
    url: '/view',
    params: {
      uuid: '',
    },
  },
  // 新增
  add: {
    url: '',
    method: 'file',
    data: {
      type: 0, // 0:文章, 1:影片, 2:音頻, 3:文庫, 4:貼文
      title: '', // 標題
      description: '', // 說明
      file: null, // 檔案
      type_file: null, // 檔案
      cover_file: '', // 封面
      tag_lists: [], // 標籤
      rank: 0, // -1:自己, 0:不限, 1:會員, 2:vip
      content: '', // 內容
      playlist_id: 0, // 清單
    },
  },
  // 編輯
  update: {
    url: '/update',
    method: 'file',
    data: {
      uuid: '',
      type: 0, // 0:文章, 1:影片, 2:音頻, 3:文庫, 4:貼文
      title: '', // 標題
      description: '', // 說明
      file: null, // 檔案
      type_file: null, // 檔案
      cover_file: '', // 封面
      tag_lists: [], // 標籤
      rank: 0, // -1:自己, 0:不限, 1:會員, 2:vip
      content: '', // 內容
      playlist_id: 0, // 清單
    },
  },
  files: {
    url: '/files',
    method: 'file',
    data: {
      uuid: '',
      files: {}, // 檔案
    },
  },
  // 播放清單下拉選擇
  playlists: {
    url: '/playlists',
  },
  // 主打知識列表
  lists: {
    url: '/lists',
  },
  // 審核
  approved: {
    method: 'post',
    url: '/approved',
    data: {
      uuid: '',
      flag: '',
    },
  },
  // 上下架
  toggle: {
    method: 'post',
    url: '/toggle',
    data: {
      uuid: '',
    },
  },
  // 更新觀看權限
  rank: {
    method: 'post',
    url: '/rank',
    data: {
      uuid: '',
      rank: '',
    },
  },
  // 刪除
  delete: {
    method: 'post',
    url: '/delete',
    data: {
      uuid: '',
    },
  },
  // 留言管理
  comments: {
    url: '/comments',
    data: {
      uuid: '',
      direction: '',
      sort: '',
    },
  },
  // 審核權限申請
  publishApply: {
    url: '/publish-apply',
    method: 'post',
  },
};
